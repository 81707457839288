<template>
  <section>
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card-actions
          ref="filters"
          title="Filtros"
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <validation-observer ref="rulesAssignTo">
            <b-row>
              <b-col cols="3">
                <validation-provider
                  #default="{ errors }"
                >
                  <b-form-group
                    label="De"
                    label-for="created_at_from"
                  >
                    <b-input-group>
                      <cleave
                        id="created_at_from"
                        v-model="runtimeFilters.created_at_from"
                        type="text"
                        class="form-control"
                        placeholder="DD/MM/YYYY"
                        autocomplete="off"
                        :raw="false"
                        show-decade-nav
                        :options="maskDate"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="created_at_from_dp"
                          show-decade-nav
                          button-only
                          button-variant="outline-primary"
                          right
                          size="sm"
                          :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
                          locale="pt-BR"
                          aria-controls="example-input"
                          @context="(ctx) => { runtimeFilters.created_at_from = (ctx.selectedDate) ? ctx.selectedFormatted : '' }"
                        />
                      </b-input-group-append>

                      <b-input-group-append v-if="runtimeFilters.created_at_from.length > 0">
                        <b-button
                          variant="outline-primary"
                          size="sm"
                          @click="runtimeFilters.created_at_from = ''"
                        >
                          <feather-icon icon="XIcon" />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>

              <b-col cols="3">
                <validation-provider
                  #default="{ errors }"
                >
                  <b-form-group
                    label="Até"
                    label-for="created_at_to"
                  >
                    <b-input-group>
                      <cleave
                        id="created_at_to"
                        v-model="runtimeFilters.created_at_to"
                        type="text"
                        class="form-control"
                        placeholder="DD/MM/YYYY"
                        autocomplete="off"
                        :raw="false"
                        show-decade-nav
                        :options="maskDate"
                      />
                      <b-input-group-append>
                        <b-form-datepicker
                          v-model="created_at_to_dp"
                          show-decade-nav
                          button-only
                          button-variant="outline-primary"
                          right
                          size="sm"
                          :date-format-options="{ day: '2-digit', month: '2-digit', year: 'numeric' }"
                          locale="pt-BR"
                          aria-controls="example-input"
                          @context="(ctx) => { runtimeFilters.created_at_to = (ctx.selectedDate) ? ctx.selectedFormatted : '' }"
                        />
                      </b-input-group-append>

                      <b-input-group-append v-if="runtimeFilters.created_at_to.length > 0">
                        <b-button
                          variant="outline-primary"
                          size="sm"
                          @click="runtimeFilters.created_at_to = ''"
                        >
                          <feather-icon icon="XIcon" />
                        </b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col cols="3">
                <validation-provider
                  #default="{ errors }"
                >
                  <b-form-group
                    label="Status"
                    label-for="status"
                  >
                    <b-form-select
                      id="status"
                      v-model="status"
                      :options="issueStatusOptions"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col cols="3">
                <validation-provider
                  #default="{ errors }"
                >
                  <b-form-group
                    label="Tags"
                    label-for="tags"
                  >
                    <v-select
                      id="tags"
                      ref="tags-select"
                      v-model="tags"
                      multiple
                      :options="issueTagOptions"
                      label="text"
                      :reduce="tag => tag.value"
                      placeholder="Todas"
                      @option:selected="$nextTick(() => $refs['tags-select'].searchEl.focus())"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3">
                <validation-provider
                  #default="{ errors }"
                >
                  <b-form-group
                    label="Criador"
                    label-for="from_id"
                  >
                    <b-form-select
                      id="from_id"
                      v-model="from_id"
                      :options="fromOptions"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col cols="3">
                <validation-provider
                  #default="{ errors }"
                >
                  <b-form-group
                    label="Responsável"
                    label-for="to_id"
                  >
                    <b-form-select
                      id="to_id"
                      v-model="to_id"
                      :options="toOptions"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
              <b-col cols="3">
                <validation-provider
                  #default="{ errors }"
                  rules="integer"
                >
                  <b-form-group
                    label="ID do usuário afetado"
                    label-for="affected_user_id"
                  >
                    <b-form-input
                      id="affected_user_id"
                      v-model="user_id"
                    />
                  </b-form-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-col>
            </b-row>
          </validation-observer>

          <b-row class="text-right">
            <b-col
              cols="12"
              class="form-buttons"
            >
              <b-form-group
                label=""
                label-for="btn-buscar"
                style="margin-top: 20px"
              >
                <b-button
                  id="btn-limpar"
                  variant="outline-secondary"
                  @click="reset()"
                >
                  Limpar
                </b-button>
                <b-button
                  id="btn-buscar"
                  variant="primary"
                  @click="getIssues()"
                >
                  Buscar
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-actions>

        <b-card v-if="isSubmited">
          <b-row>
            <b-col>
              <b-table
                :busy="isLoading"
                fixed
                :fields="result_fields"
                :items="results"
                sort-icon-left
                no-local-sorting
                :sort-by.sync="filtersValues.sortBy"
                :sort-desc.sync="filtersValues.sortDesc"
                @sort-changed="sortingChanged"
              >
                <template #table-busy>
                  <div class="text-center table-spinner">
                    <b-spinner label="Loading..." />
                    <strong>Carregando...</strong>
                  </div>
                </template>

                <template #head(tags)>
                  Tags
                </template>

                <template #head(title)>
                  Título
                </template>

                <template #head(created_at)>
                  Abertura
                </template>

                <template #head(updated_at)>
                  Atualização
                </template>

                <template #head(users)>
                  Envolvidos<br>
                  <small class="font-small-1">
                    De/Para/Usuário
                  </small>
                </template>

                <template #cell(id)="row">
                  <b-button
                    variant="primary"
                    @click="$root.$emit('cms::issue-sidebar', { issue: row.item, open: true })"
                  >
                    {{ row.item.id }}
                    <feather-icon
                      icon="AlignRightIcon"
                      size="14"
                    />
                  </b-button>
                </template>

                <template #cell(tags)="row">
                  <b-badge
                    v-for="tag in row.item.tags"
                    :key="tag"
                    pill
                    style="margin: 0 5px 5px 0"
                  >
                    {{ $t('issue.tag')[tag] }}
                  </b-badge>
                </template>

                <template #cell(created_at)="row">
                  {{ formatDateTimeDbToView(row.item.created_at) }}
                </template>

                <template #cell(updated_at)="row">
                  {{ formatDateTimeDbToView(row.item.updated_at) }}
                </template>

                <template #cell(users)="row">
                  <b-avatar-group
                    size="32px"
                    overlap=".1"
                  >
                    <b-avatar
                      v-b-tooltip.hover="row.item.from && row.item.from.name ? row.item.from.name : 'Sem criador'"
                      :src="row.item.from && row.item.from.avatar ? row.item.from.avatar.sm : ''"
                      variant="light-success"
                    >
                      <b-img
                        v-if="!row.item.from"
                        :src="appLogoImage"
                        alt="logo"
                        style="width: 20px"
                      />
                    </b-avatar>
                    <b-avatar
                      v-if="row.item.to_all_from"
                      v-b-tooltip.hover="`Equipe ${$t(`roles.${row.item.to_all_from}`)}`"
                      :src="''"
                      variant="light-success"
                    >
                      <b-img
                        :src="appLogoImage"
                        alt="logo"
                        style="width: 20px"
                      />
                    </b-avatar>
                    <b-avatar
                      v-else
                      v-b-tooltip.hover="row.item.to && row.item.to.name ? row.item.to.name : `Equipe ${$t('roles.management_processes')}`"
                      :src="row.item.to && row.item.to.avatar ? row.item.to.avatar.sm : ''"
                      variant="light-success"
                    >
                      <b-img
                        v-if="!row.item.to"
                        :src="appLogoImage"
                        alt="logo"
                        style="width: 20px"
                      />
                    </b-avatar>
                    <b-avatar
                      v-if="!row.item.user"
                      v-b-tooltip.hover="row.item.user && row.item.user.name ? row.item.user.name : 'Sem usuário'"
                      :src="row.item.user && row.item.user.avatar ? row.item.user.avatar.sm : ''"
                      variant="light-success"
                    />
                  </b-avatar-group>
                </template>

                <template #cell(status)="row">
                  <b-badge
                    pill
                    :variant="{
                      closed: 'success',
                      open: 'danger',
                    }[row.item.status]"
                  >
                    {{ $t('issue.status')[row.item.status] }}
                  </b-badge>
                  <div v-if="row.item.status === 'open'">
                    {{ row.item.from.name }}<br>
                    {{ formatDateTimeDbToView(row.item.created_at) }}
                  </div>
                  <div v-if="row.item.status === 'closed'">
                    {{ row.item.finisher.name }}<br>
                    {{ formatDateTimeDbToView(row.item.finished_at) }}
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6">
              <p>Mostrando {{ pages.from }} até {{ pages.to }} de {{ pages.total }} resultados</p>
            </b-col>

            <b-col cols="6">
              <!-- pagination nav -->
              <b-pagination-nav
                v-model="currentPage"
                :number-of-pages="pages.last_page"
                base-url="#"
                align="end"
              />
            </b-col>
          </b-row>

        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BBreadcrumb,
  BBadge,
  BFormDatepicker,
  BButton,
  BAvatar,
  BAvatarGroup,
  BCard,
  BCol,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BFormSelect,
  BRow,
  BImg,
  BSpinner,
  BPaginationNav,
  BTable,
  VBTooltip,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import { maskDate } from '@/utils/masks'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  issueStatusOptions,
  issueTagOptions,
  managersOptions,
  months,
  withEmptyOptionFirst,
} from '@/utils/options'
import { regex } from '@validations'
import IssueService from '@/services/issueService'
import toast from '@/mixins/toast'
import { formatDateTimeDbToView } from '@/utils/helpers'
import { $themeConfig } from '@themeConfig'

import moment from 'moment/moment'

export default {
  name: 'IssuesList',
  components: {
    BFormDatepicker,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BFormSelect,
    BBreadcrumb,
    BBadge,
    BButton,
    BAvatar,
    BAvatarGroup,
    BCard,
    BCardActions,
    BCol,
    BFormGroup,
    BRow,
    BImg,
    BSpinner,
    BTable,
    BPaginationNav,
    Cleave,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [
    toast,
  ],
  data() {
    return {
      regex,

      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Chamados',
          active: true,
        },
      ],
      userData: null,
      created_at_from_dp: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      created_at_to_dp: '',
      from_id: '',
      to_id: '',
      user_id: '',
      status: '',
      tags: [],
      issueTagOptions,
      filtersValues: {
        sortBy: 'id',
        sortDesc: true,
      },
      runtimeFilters: {
        created_at_from: '',
        created_at_to: '',
      },
      months,
      isLoading: true,
      isSubmited: false,
      goalToDeleteWhenEditing: null,
      maskDate,
      pages: 1,
      currentPage: 1,
      results: [],
      result_fields: [],
      issueService: null,
      toOptions: withEmptyOptionFirst(managersOptions.filter(f => f.label !== 'Removidos')),
      fromOptions: withEmptyOptionFirst(managersOptions.filter(f => f.label !== 'Removidos')),
      issueStatusOptions: withEmptyOptionFirst(issueStatusOptions),
      appLogoImage: $themeConfig.app.appLogoImage,
    }
  },
  computed: {},
  watch: {
    currentPage() {
      this.getIssues()
    },
  },
  async created() {
    const userDataString = await localStorage.getItem('userData')
    this.userData = await JSON.parse(userDataString)

    this.from_id = this.userData ? this.userData.id : ''

    this.result_fields = [
      { key: 'id', sortable: false },
      { key: 'tags', sortable: false },
      { key: 'users', sortable: false },
      { key: 'title', sortable: false },
      { key: 'created_at', sortable: false },
      { key: 'updated_at', sortable: false },
      { key: 'status', sortable: false },
    ]
  },
  methods: {
    formatDateTimeDbToView,
    reset() {
      this.from_id = ''
      this.to_id = ''
      this.user_id = ''
      this.status = ''
      this.tags = []
      this.runtimeFilters = Object.fromEntries(Object.entries(this.runtimeFilters).map(item => {
        // eslint-disable-next-line no-param-reassign
        item[1] = ''
        return item
      }))
    },
    transformDate(date) {
      if (date.length === 10) {
        const from = date.split('/')
        return `${from[2]}-${from[1]}-${from[0]}`
      }
      return ''
    },
    getIssues() {
      this.$refs.rulesAssignTo.validate().then(success => {
        if (!success) {
          return
        }

        this.isSubmited = true
        this.isLoading = true

        const dateFrom = this.runtimeFilters.created_at_from ? this.transformDate(this.runtimeFilters.created_at_from) : ''
        const dateTo = this.runtimeFilters.created_at_to ? this.transformDate(this.runtimeFilters.created_at_to) : ''

        const {
          from_id,
          to_id,
          user_id,
          status,
          tags,
        } = this

        IssueService.list({
          page: this.currentPage,
          dateFrom,
          dateTo,
          from_id,
          to_id,
          user_id,
          status,
          tags,
        }).then(response => {
          this.results = response.data.data.issues.data
          this.pages = { ...response.data.data.issues, data: null }
        }).finally(() => {
          this.isLoading = false
        })
      })
    },
    sortingChanged(ctx) {
      if (ctx.sortBy !== '') {
        this.filtersValues.sortBy = ctx.sortBy
        this.filtersValues.sortDesc = ctx.sortDesc
        this.getIssues()
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.text-danger {
  font-weight: bold;
}
</style>
